import { gql, QueryResult, useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect } from 'react';
import { AuthenticatedUserQuery } from '@pv/common/graphql/operations';

type User = AuthenticatedUserQuery['authenticatedUser'];

const atlasEnabled = process.env.REACT_APP_PV_ENV === 'production';

const userQuery = gql`
  query AuthenticatedUser {
    authenticatedUser {
      id
      publicId
      atlasHash
      phone
      email
      firstName
      lastName
      fullName
      image
      signatureBlock
      connectedGoogleCalendar
      showLeadsOnGoogleCalendar
      createdAt
      role
      suggestMessages
      merriConnected
      prismmConnected
      sevenShiftsConnected
      organizationId
      organization {
        id
        canUpdate {
          value
        }
        canManageBilling {
          value
        }
        canCancelEvents {
          value
        }
        canManageExpressBookRequests {
          value
        }
        name
        billingEmail
        stripeCustomerId
        stripeLast4
        freeTrialEndsAt
        image
        banner
        contactFormBackgroundColor
        helloLeadFormPath
        helloLeadFormUrl
        reserveConnected
        featureFlags
      }
      organizationMembership {
        id
        role
        canUpdate {
          value
        }
        canDestroy {
          value
        }
        newLeadsEmail
        newLeadsSms
        paymentEmails
        teamMessageEmail
        initialMember
      }
      organizationMemberships {
        id
        role
        canUpdate {
          value
        }
        canDestroy {
          value
        }
        newLeadsEmail
        newLeadsSms
        paymentEmails
        teamMessageEmail
        organization {
          id
          canUpdate {
            value
          }
          canManageBilling {
            value
          }
          name
          billingEmail
          stripeCustomerId
          stripeLast4
          freeTrialEndsAt
          image
          banner
          contactFormBackgroundColor
          helloLeadFormPath
          helloLeadFormUrl
        }
      }
      activeVenueMemberships {
        id
        venue {
          id
          name
          internalName
          merriId
          sevenShiftsId
          prismmId
          reserveRestaurantId
          venueCollection {
            id
            name
          }
        }
      }
    }
  }
`;

interface AuthContext {
  user: User | null;
}

interface UserContext extends AuthContext {
  freeTrialEndsAt: Date | null;
  refetchAuthenticatedUser: QueryResult['refetch'];
}
const AuthenticatedUserContext = createContext<UserContext>({
  user: null,
  freeTrialEndsAt: null,
  refetchAuthenticatedUser: () => false as any,
});
export const useUserAuth = () => useContext(AuthenticatedUserContext);

interface ProviderProps {
  children: JSX.Element;
}

export const AuthUserProvider = ({ children }: ProviderProps) => {
  const { data, refetch, loading } =
    useQuery<AuthenticatedUserQuery>(userQuery);

  const user = data?.authenticatedUser;

  useEffect(() => {
    if (atlasEnabled && window.Atlas && user) {
      window.Atlas.call('identify', {
        userId: user.publicId,
        name: user.fullName,
        email: user.email,
        userHash: user.atlasHash,
      });
    }
  }, [user]);

  if (loading) {
    return null;
  }

  const organization = user?.organization;

  const freeTrialEndsAt = organization?.freeTrialEndsAt;
  const value = {
    user,
    freeTrialEndsAt,
    refetchAuthenticatedUser: refetch,
  };

  return (
    <AuthenticatedUserContext.Provider value={value}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
