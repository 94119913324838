import { gql, useQuery } from '@apollo/client';
import { useUserAuth } from '../providers/auth';

const GET_EVENT_TYPES = gql`
  query GetEventTypes($id: ID!) {
    organization(id: $id) {
      id
      eventTypes {
        id
        name
      }
    }
  }
`;
export const useEventTypes = () => {
  const { user } = useUserAuth();
  const orgId = user?.organization?.id;

  const { data, loading, refetch } = useQuery(GET_EVENT_TYPES, {
    variables: { id: orgId },
    skip: !orgId,
  });

  const eventTypes = data?.organization?.eventTypes || [];
  const eventTypeOptions =
    eventTypes?.map(
      (eventType: { id: number; name: string }) => eventType.name
    ) || [];
  return { eventTypes, eventTypeOptions, loading, refetch };
};
