import React, { useRef, useState } from 'react';
import {
  Divider,
  Button,
  Typography,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Fade,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import size from 'lodash/size';
import first from 'lodash/first';
import map from 'lodash/map';
import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import { sortVenuesByName } from '@pv/common/utils';
import { ChevronDown as ChevronDownIcon } from 'react-feather';

import { useUserAuth } from '../../../../../providers/auth';
import { useVenues } from '../../../../../providers/venues';

interface Venue {
  id: string;
  name: string;
  internalName?: string | null;
}

interface VenueMembership {
  id: string;
  venue: Venue;
}

const AccordionSelect: React.FC<{
  title: string;
  children: React.ReactNode;
  expanded: boolean;
  handleChange: (panel: string | false) => void;
  id: string;
}> = ({ title, children, expanded, handleChange, id }) => {
  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={() => {
          if (expanded) {
            handleChange(false);
          } else {
            handleChange(title);
          }
        }}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          aria-controls={id}
          sx={{
            minHeight: 'auto',
            padding: '6px 16px',
            '&.Mui-expanded': {
              margin: 0,
              minHeight: 'auto',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: 0,
              minHeight: 'auto',
            },
            '& .MuiAccordionSummary-content': {
              margin: 0,
              minHeight: 'auto',
            },
          }}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails id={id} sx={{ padding: 0 }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const SingleVenue = ({
  venueLabel,
  truncatedVenueNameLength,
}: {
  venueLabel: string;
  truncatedVenueNameLength: string;
}) => {
  const theme = useTheme();
  return (
    <Fade in={!!venueLabel}>
      <div>
        <div
          className="venue-menu-button"
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: `0px ${theme.spacing(3)}px`,
          }}
        >
          <Typography
            noWrap
            style={{
              margin: `0px ${theme.spacing(1)}px`,
              maxWidth: truncatedVenueNameLength,
            }}
            color="inherit"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {venueLabel}
          </Typography>
        </div>
      </div>
    </Fade>
  );
};

const VenueDropDown = () => {
  const accountRef = useRef(null);
  const theme = useTheme();
  const { user } = useUserAuth();
  const { venues, setCurrentVenueMemberships } = useVenues();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleOpenMenuClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  let venueLabel;
  let selectedVenue: Venue | undefined;
  if (size(venues) === 1) {
    selectedVenue = first(venues);
    venueLabel = selectedVenue?.internalName || selectedVenue?.name;
  } else if (size(venues) > 1) {
    venueLabel = 'All Venues';
  } else {
    venueLabel = 'No active venues';
  }

  const mediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const shouldTruncateVenueName = mediumScreen && size(venueLabel) > 15;
  const truncatedVenueNameLength = shouldTruncateVenueName ? '90px' : 'unset';
  const setCurrentVenueMembershipAndClose: (
    venueMembership: VenueMembership
  ) => void = (venueMembership) => {
    setCurrentVenueMemberships([venueMembership], true);
    handleCloseMenu();
  };

  const isDropDown = size(user?.activeVenueMemberships) > 1;

  const onClickAll = () => {
    if (user) {
      setCurrentVenueMemberships(user.activeVenueMemberships, true);
      handleCloseMenu();
    }
  };

  if (!isDropDown) {
    return (
      <SingleVenue
        venueLabel={venueLabel || ''}
        truncatedVenueNameLength={truncatedVenueNameLength}
      />
    );
  }

  const venueMemberships = user?.activeVenueMemberships || [];

  const venueMembershipsWithCollection = venueMemberships.filter(
    (venueMembership) => {
      return venueMembership.venue.venueCollection;
    }
  );

  const venueMembershipsWithoutCollection = venueMemberships.filter(
    (venueMembership) => {
      return !venueMembership.venue.venueCollection;
    }
  );

  const venueMembershipsGroupedByCollection = groupBy(
    venueMembershipsWithCollection,
    'venue.venueCollection.name'
  );

  const venueMembershipGroups = Object.entries(
    venueMembershipsGroupedByCollection
  )
    .map(([collectionName, venueMemberships]) => {
      return {
        collectionName,
        venueMemberships: venueMemberships.sort((a, b) =>
          sortVenuesByName(a.venue, b.venue)
        ),
      };
    })
    .sort((a, b) => a.collectionName.localeCompare(b.collectionName));

  const alphabetizedVenueMemberships = [
    ...venueMembershipsWithoutCollection,
  ].sort((a, b) => sortVenuesByName(a.venue, b.venue));

  return (
    <Fade in={some(venues)}>
      <div>
        <Button
          variant="text"
          className="venue-menu-button"
          id="venue-selector-button"
          onClick={handleOpenMenuClick}
          aria-label="select venue"
          aria-controls={open ? 'venue-selector-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          ref={accountRef}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: `0px ${theme.spacing(1)}px`,
            minWidth: 100,
            color: 'white',
          }}
        >
          <Typography
            noWrap
            style={{
              margin: `0px ${theme.spacing(1)}px`,
              maxWidth: truncatedVenueNameLength,
            }}
            color="inherit"
            className="venue-button"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {venueLabel}
          </Typography>
          {venueLabel && <ChevronDownIcon />}
        </Button>
        <Menu
          id="venue-selector-menu"
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'venue-selector-button',
          }}
        >
          <MenuItem onClick={onClickAll}>All Venues</MenuItem>
          <Divider />
          {map(venueMembershipGroups, (venueCollection, i) => (
            <AccordionSelect
              expanded={expanded === venueCollection.collectionName}
              title={venueCollection.collectionName}
              key={venueCollection.collectionName}
              handleChange={setExpanded}
              id={`venue-membership-group-${i}`}
            >
              {map(venueCollection.venueMemberships, (venueMembership) => (
                <MenuItem
                  key={venueMembership.id}
                  selected={
                    selectedVenue &&
                    venueMembership.venue.id === selectedVenue.id
                  }
                  onClick={() =>
                    setCurrentVenueMembershipAndClose(venueMembership)
                  }
                  sx={{
                    paddingLeft: '32px',
                  }}
                >
                  {venueMembership.venue.internalName ||
                    venueMembership.venue.name}
                </MenuItem>
              ))}
            </AccordionSelect>
          ))}
          {map(alphabetizedVenueMemberships, (venueMembership) => (
            <MenuItem
              key={venueMembership.id}
              selected={
                selectedVenue && venueMembership.venue.id === selectedVenue.id
              }
              onClick={() => setCurrentVenueMembershipAndClose(venueMembership)}
            >
              {venueMembership.venue.internalName || venueMembership.venue.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Fade>
  );
};

export default VenueDropDown;
