import { useUserAuth } from '../providers/auth';

const UserRole = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  VIEWER: 'viewer',
  LIMITED_VIEW: 'limited_view',
};

export const usePermissions = () => {
  const { user } = useUserAuth();
  const role = user?.role;

  const canEdit = role === UserRole.ADMIN || role === UserRole.EDITOR;
  const isAdmin = role === UserRole.ADMIN;
  const canCreateEvents = canEdit;
  const canViewInbox = canEdit;
  return {
    canEdit,
    isAdmin,
    canCreateEvents,
    canViewInbox,
  };
};
