import { IconButton, Box } from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import { Fields } from '@pv/common/components';
import { useVenues } from '../../providers/venues';
import { useEvent } from '../../providers/event';
import SpaceSelector, {
  spaceValsFromVenue,
} from '../../components/SpaceSelector';
import {
  updateStartOffset,
  updateEndOffset,
  updateStartDate,
  updateEndDate,
  getOffset,
} from './utils';
import { dollarsToCents } from '@pv/common/utils';
import moment from 'moment';
import { Calendar as CalendarIcon } from 'react-feather';
import { SectionContainer, RowContainer, HalfRowContainer } from './components';
import { AutoSave } from '../../components/AutoSave';
import { useEventTypes } from '../../hooks/useEventTypes';
const EventInfoArea = ({
  resetHack,
  onSave,
  onDayViewClick,
  allowDayView,
  updateEventImmediately,
}) => {
  const { event, eventVenue } = useEvent();
  const canUpdateEvent = event?.canUpdate?.value;
  const { venues } = useVenues();
  const { eventTypeOptions, loading } = useEventTypes();
  const eventTypeOptionsLoading = loading;
  const {
    user,
    venue,
    groupSize: eventGroupSize,
    startDate: eventStartDate,
    startOffset: eventStartOffset,
    endDate: eventEndDate,
    endOffset: eventEndOffset,
    budget: eventBudget,
    canShowEventBudget: showEventBudget,
  } = event;

  const canShowEventBudget = showEventBudget?.value;

  const multiDayEnabled = event.multiDayEnabled || eventVenue.multiDayEnabled;

  const [venueId, setVenueId] = useState(venue?.id);
  const [startDate, setStartDate] = useState(eventStartDate);
  const [startOffset, setStartOffset] = useState(eventStartOffset);
  const [endDate, setEndDate] = useState(eventEndDate);
  const [endOffset, setEndOffset] = useState(eventEndOffset);
  const [spaceIds, setSpaceIds] = useState(
    map(event.spaces, (space) => space.id)
  );

  useEffect(() => {
    setSpaceIds(map(event.spaces, (space) => space.id));
  }, [event.spaces]);

  // This is a terrible hack so that we can reset this state, when we get an error from the mutation
  // defined in the parent.
  useEffect(() => {
    setStartDate(event.startDate);
    setStartOffset(event.startOffset || '');
    setEndDate(event.endDate);
    setEndOffset(event.endOffset || '');
  }, [
    resetHack,
    event.startDate,
    event.startOffset,
    event.endDate,
    event.endOffset,
  ]);

  const premiumFeaturesEnabled = useMemo(
    () => eventVenue?.featureScope?.premiumFeaturesEnabled,
    [eventVenue]
  );

  const venuesVals = map(venues, (v) => [v.id, v.internalName || v.name]);
  const multiVenue = size(venues) > 1;

  const users = map(eventVenue?.venueMemberships, (vm) => vm.user);
  const userValsSansDefault = map(users, (u) => [u.id, u.fullName]);
  const userVals = [['No Owner', 'No Owner', false], ...userValsSansDefault];

  const updateDateTimeRange = useCallback(
    ({ updatedValue }) => {
      const effectiveEndDate = multiDayEnabled ? endDate : startDate;
      let updatedRange = {
        startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
        endDate:
          effectiveEndDate && moment(effectiveEndDate).format('YYYY-MM-DD'),
      };

      if (startOffset) {
        updatedRange.startOffset = startOffset;
      }
      if (endOffset) {
        updatedRange.endOffset = endOffset;
      }
      updatedRange = { ...updatedRange, ...updatedValue };

      onSave({
        dateTimeRange: updatedRange,
      });
    },
    [onSave, startDate, startOffset, endDate, endOffset, multiDayEnabled]
  );

  const onChangeVenueId = (vid) => {
    const newVenue = find(venues, (v) => v.id === vid);
    if (
      window.confirm(
        `Are you sure you want to move this event to ${newVenue.name}?\nYou may lose data in custom fields.`
      )
    ) {
      const input = { id: event.id, venueId: newVenue.id, spaceIds: [] };
      const variables = { input };
      setVenueId(vid);
      setSpaceIds([]);
      updateEventImmediately({ variables });
    }
  };

  useEffect(() => {
    updateStartDate(startDate, updateDateTimeRange);
  }, [startDate, updateDateTimeRange]);

  useEffect(() => {
    updateStartOffset(startOffset, updateDateTimeRange);
  }, [startOffset, updateDateTimeRange]);

  useEffect(() => {
    updateEndDate(endDate, updateDateTimeRange);
  }, [endDate, updateDateTimeRange]);

  useEffect(() => {
    updateEndOffset(endOffset, updateDateTimeRange);
  }, [endOffset, updateDateTimeRange]);

  const startDateLabel = multiDayEnabled ? 'Start Date' : 'Date';

  return (
    <SectionContainer data-cy="event-page-info">
      <RowContainer gap="8px">
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Fields.PvDateField
            fullWidth
            variant="outlined"
            value={startDate}
            label={startDateLabel}
            onChange={(d) => setStartDate(d)}
            formatString="dddd, MMM D, YYYY"
            data-cy="event-page-date"
            disabled={!canUpdateEvent}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <IconButton
              color="inherit"
              disabled={!allowDayView}
              onClick={onDayViewClick}
            >
              <CalendarIcon />
            </IconButton>
          </Box>
        </Box>
      </RowContainer>
      {multiDayEnabled && (
        <Box>
          <Fields.PvDateField
            fullWidth
            variant="outlined"
            label="End Date"
            value={endDate}
            onChange={(d) => setEndDate(d)}
            formatString="dddd, MMM D, YYYY"
            disabled={!canUpdateEvent}
          />
        </Box>
      )}
      <RowContainer>
        <HalfRowContainer>
          <Fields.PvTimeField
            variant="outlined"
            label="Start Time"
            value={startOffset}
            onChange={(e) => setStartOffset(getOffset(e.target.value))}
            data-cy="event-page-start-time"
            disabled={!canUpdateEvent}
          />
        </HalfRowContainer>
        <HalfRowContainer>
          <Fields.PvTimeField
            variant="outlined"
            value={endOffset || ''}
            label="End Time"
            onChange={(e) => setEndOffset(getOffset(e.target.value))}
            data-cy="event-page-end-time"
            disabled={!canUpdateEvent}
          />
        </HalfRowContainer>
      </RowContainer>
      {multiVenue && (
        <Box>
          <Fields.PvDropDown
            variant="outlined"
            name="venue"
            label="Venue"
            vals={venuesVals}
            onChange={(e) => onChangeVenueId(e.target.value)}
            value={venueId}
            disabled={!canUpdateEvent}
          />
        </Box>
      )}
      <RowContainer>
        <HalfRowContainer>
          <AutoSave
            value={eventGroupSize}
            onSave={(value) => {
              onSave({ groupSize: value });
            }}
            renderField={(value, onChange) => (
              <Fields.PvIntegerField
                variant="outlined"
                name="groupSize"
                value={value}
                label="Group Size"
                onChange={(e) => onChange(e.target.value)}
                data-cy="event-page-group-size"
                disabled={!canUpdateEvent}
              />
            )}
          />
        </HalfRowContainer>
        {canShowEventBudget && (
          <HalfRowContainer>
            <AutoSave
              value={eventBudget / 100}
              onSave={(value) => {
                onSave({ budget: dollarsToCents(value) });
              }}
              renderField={(value, onChange) => (
                <Fields.PvCurrencyMaskField
                  variant="outlined"
                  fullWidth
                  label="Budget"
                  value={value}
                  name="budget"
                  onChange={(e) => onChange(e.target.value)}
                  showCents={false}
                  data-cy="event-page-budget"
                  disabled={!canUpdateEvent}
                />
              )}
            />
          </HalfRowContainer>
        )}
      </RowContainer>

      <Box>
        <AutoSave
          value={spaceIds}
          onSave={(value) => onSave({ spaceIds: value })}
          renderField={(value, onChange) => (
            <SpaceSelector
              variant="outlined"
              name="space"
              label="Space"
              spaceAvailability={event?.spaceAvailability}
              vals={spaceValsFromVenue({ venue: eventVenue })}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              disabled={!canUpdateEvent}
            />
          )}
        />
      </Box>
      <Box>
        {!eventTypeOptionsLoading && (
          <AutoSave
            value={event.eventTypeName || ''}
            onSave={(value) => {
              if (value === 'None') {
                onSave({ eventTypeName: '' });
              } else {
                onSave({ eventTypeName: value });
              }
            }}
            renderField={(value, onChange) => (
              <Fields.PvDropDown
                variant="outlined"
                value={value}
                label="Event Type"
                onChange={(e) => onChange(e.target.value)}
                vals={[
                  ['None', 'None'],
                  ...eventTypeOptions.map((et) => [et, et]),
                ]}
                name="eventTypeName"
                disabled={!canUpdateEvent}
              />
            )}
          />
        )}
      </Box>
      {premiumFeaturesEnabled && (
        <Box>
          <div className="owner-field-wrapper">
            <AutoSave
              value={user?.id || 'No Owner'}
              onSave={(value) => {
                if (value === 'No Owner') {
                  onSave({ userId: '-1' });
                } else {
                  onSave({ userId: value });
                }
              }}
              renderField={(value, onChange) => (
                <Fields.PvDropDown
                  variant="outlined"
                  value={value}
                  label="Owner"
                  onChange={(e) => onChange(e.target.value)}
                  vals={userVals}
                  name="owner"
                  disabled={!canUpdateEvent}
                />
              )}
            />
          </div>
        </Box>
      )}
    </SectionContainer>
  );
};

export default EventInfoArea;
