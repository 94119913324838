import React, { useState, useMemo } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  styled,
} from '@mui/material';
import { useUserAuth } from '../../providers/auth';

import { CreateEventModal } from '../../events';
import { Calendar, ShoppingCart, Mail, Plus } from 'react-feather';
import { ConfettiIcon } from '../icons';
import { usePermissions } from '../../hooks/usePermissions';
export const mobileAppbarHeight = 60;

export const isMobileNavBarVisible = (pathname) => {
  return (
    pathname === '/events' ||
    pathname === '/calendar' ||
    pathname === '/tasks' ||
    pathname === '/inbox' ||
    pathname === '/express_book'
  );
};

const StyledBottomNavTab = styled(BottomNavigationAction)(({ theme }) => ({
  color: theme.palette.info.contrastText,

  '&.Mui-selected': {
    color: theme.palette.secondary.main,
    borderTop: `4px solid ${theme.palette.secondary.main}`,
  },
}));

export const MobileNavBar = () => {
  const navigate = useNavigate();

  const { canViewInbox } = usePermissions();

  const [open, setOpen] = useState(false);
  const onClose = (newEvent) => {
    setOpen(false);
    if (newEvent && newEvent.id) {
      navigate('/events/' + newEvent.id);
    }
  };
  const currentTab = useLocation().pathname;

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
      elevation={3}
    >
      <BottomNavigation value={currentTab} showLabels>
        <StyledBottomNavTab
          component={RouterLink}
          icon={<ConfettiIcon />}
          label="Events"
          to="/events"
          value="/events"
          selected={currentTab === '/events'}
        />

        <StyledBottomNavTab
          to="/express_book"
          value="/express_book"
          component={RouterLink}
          icon={<ShoppingCart />}
          label="Express"
          selected={currentTab === '/express_book'}
        />

        {canViewInbox && (
          <StyledBottomNavTab
            to="/inbox"
            value="/inbox"
            component={RouterLink}
            icon={<Mail />}
            label="Inbox"
            selected={currentTab === '/inbox'}
          />
        )}

        <StyledBottomNavTab
          to="/calendar"
          value="/calendar"
          component={RouterLink}
          icon={<Calendar />}
          label="Calendar"
          selected={currentTab === '/calendar'}
        />

        <StyledBottomNavTab
          onClick={() => setOpen(true)}
          icon={<Plus />}
          label="Add Event"
        />

        <CreateEventModal open={open} onClose={onClose} />
      </BottomNavigation>
    </Paper>
  );
};

export default MobileNavBar;
