import { gql } from '@apollo/client';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import first from 'lodash/first';
import find from 'lodash/find';
import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import validator from 'validator';
import { useMutation } from '@pv/common/hooks';
import { dollarsToCents } from '@pv/common/utils';
import { useVenues } from '../../providers/venues';
import { useEvents } from '../../providers/events';
import EventStatusDropDown from './EventStatusDropDown';
import NewContactArea from '../../components/NewContactArea';
import NewEventInfoArea from './NewEventInfoArea';
import { PvDialog } from '@pv/common/components';
import { enqueueSnackbar } from 'notistack';
import { eventFieldsFragment } from '../../pages/Calendar/graphql';
import { duplicateEventMutation } from '../EventDrawer/graphql';
const createEventMutation = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      event {
        id
        ...CalendarEventFields
      }
      errors {
        message
      }
    }
  }
  ${eventFieldsFragment}
`;

export const CreateEventModal = ({
  open,
  onClose,
  initialStartDate = null,
  initialStartOffset = null,
  eventToDuplicate = null,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { venues } = useVenues();

  const { events, setEvents } = useEvents();

  const [contactView, setContactView] = useState('search');
  const [forceValidate, setForceValidate] = useState(false);

  const [eventName, setEventName] = useState('');
  const [groupSize, setGroupSize] = useState(null);
  const [venueId, setVenueId] = useState(
    eventToDuplicate?.venue?.id || first(venues)?.id
  );

  const [spaceIds, setSpaceIds] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startOffset, setStartOffset] = useState();
  const [endOffset, setEndOffset] = useState();
  const [budget, setBudget] = useState('');
  const [eventTypeName, setEventTypeName] = useState('');
  const [planner, setPlanner] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [accountName, setAccountName] = useState('');
  const [status, setStatus] = useState('lead');

  const setStartDateAndMaybeEndDate = (d) => {
    setStartDate(d);
    if (!endDate) {
      setEndDate(d);
    }
  };

  const setEndDateAndMaybeStartDate = (d) => {
    setEndDate(d);
    if (!startDate) {
      setStartDate(d);
    }
  };

  const venue = useMemo(() => find(venues, { id: venueId }), [venues, venueId]);
  const multiDayEnabled =
    venue?.featureScope?.premiumFeaturesEnabled && venue?.multiDayEnabled;

  useEffect(() => {
    setSpaceIds([]);
  }, [venueId]);

  useEffect(() => {
    if (eventToDuplicate) {
      setEventName(eventToDuplicate.name);
      setGroupSize(eventToDuplicate.groupSize);
      setVenueId(eventToDuplicate.venue.id);
      setSpaceIds(eventToDuplicate.spaces.map((s) => s.id));
      setStartDate(eventToDuplicate.startDate);
      setEndDate(eventToDuplicate.endDate);
      setStartOffset(eventToDuplicate.startOffset);
      setEndOffset(eventToDuplicate.endOffset);
      setBudget(eventToDuplicate.budget / 100);
      setStatus(eventToDuplicate.status);
      setContactView('search');
      setForceValidate(false);
      setEventTypeName(eventToDuplicate.eventTypeName);
    } else if (venues) {
      setEventName('');
      setGroupSize(null);
      setVenueId(first(venues)?.id);
      setSpaceIds([]);
      setStartDate(initialStartDate);
      setEndDate(initialStartDate);
      if (initialStartOffset) {
        setStartOffset(initialStartOffset);
        setEndOffset(initialStartOffset + 120);
      } else {
        setStartOffset(null);
        setEndOffset(null);
      }
      setBudget('');
      setPlanner(null);
      setStatus('lead');
      setContactView('search');
      setForceValidate(false);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setAccountName('');
      setEventTypeName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, venues, eventToDuplicate]);

  useEffect(() => {
    if (initialStartDate) {
      setStartDate(initialStartDate);
    }
    if (initialStartOffset) {
      setStartOffset(initialStartOffset);
    }
  }, [initialStartDate, initialStartOffset]);

  useEffect(() => {
    if (planner) {
      setFirstName(planner.firstName || '');
      setLastName(planner.lastName || '');
      setEmail(planner.email || '');
      setPhone(planner.phone || '');
      setAccountName(planner.account?.name || '');
    }
  }, [planner, planner?.id]);

  const [createEvent, { loading }] = useMutation(createEventMutation, {
    onNoErrorsCompleted: ({ createEvent }) => {
      const newEvent = createEvent.event;
      enqueueSnackbar('Event created successfully', { variant: 'success' });
      setEvents([createEvent.event, ...events]);
      onClose(newEvent);
    },
  });

  const [duplicateEvent] = useMutation(duplicateEventMutation, {
    onNoErrorsCompleted: ({ duplicateEvent }) => {
      const newEvent = duplicateEvent.event;
      enqueueSnackbar('Event duplicated successfully', { variant: 'success' });
      setEvents([duplicateEvent.event, ...events]);
      onClose(newEvent);
    },
  });

  const onClickSearchResult = (planner) => {
    setContactView('view');
    setPlanner(planner);
  };

  const onClickCreateNewPlanner = (firstName) => {
    setContactView('edit');
    setPlanner();
    setFirstName(firstName);
  };

  const onConfirm = async () => {
    if (!eventName) {
      setForceValidate(true);
      return;
    }
    if (!planner && !firstName) {
      setForceValidate(true);
      return;
    }
    if (email && !validator.isEmail(email)) {
      setForceValidate(true);
      return;
    }
    let dateTimeRange;
    if (startDate || endDate) {
      dateTimeRange = {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(multiDayEnabled ? endDate : startDate).format(
          'YYYY-MM-DD'
        ),
        startOffset: parseInt(startOffset),
        endOffset: parseInt(endOffset),
      };
    }
    const input = {
      venueId,
      spaceIds,
      status,
      name: eventName,
      groupSize: parseInt(groupSize),
      budget: dollarsToCents(budget),
      dateTimeRange,
      origin: 'dashboard',
      eventTypeName,
    };

    if (planner) {
      input.plannerId = planner.id;
    } else {
      input.planner = {
        firstName,
        lastName,
        email,
        phone,
        accountName,
      };
    }
    const variables = { input };
    if (eventToDuplicate) {
      variables.input.duplicateEventId = eventToDuplicate.id;
      duplicateEvent({ variables });
    } else {
      variables.input.sendEmails = false;
      createEvent({ variables });
    }
  };

  const title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: theme.spacing(2), flexGrow: 1 }}>
        {eventToDuplicate ? <>Duplicate&nbsp;Event</> : <>Add&nbsp;Event</>}
      </div>

      <EventStatusDropDown status={status} setStatus={setStatus} />
    </div>
  );
  return (
    <PvDialog
      dialogClassName="create-event-modal"
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      confirmButtonText="Save"
      confirmDisabled={loading}
      onConfirm={onConfirm}
      maxWidth="md"
      fullScreen={smallScreen}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <NewEventInfoArea
            eventName={eventName}
            setEventName={setEventName}
            groupSize={groupSize}
            setGroupSize={setGroupSize}
            venueId={venueId}
            setVenueId={setVenueId}
            spaceIds={spaceIds}
            setSpaceIds={setSpaceIds}
            startDate={startDate}
            setStartDate={setStartDateAndMaybeEndDate}
            startOffset={startOffset}
            setStartOffset={setStartOffset}
            endDate={endDate}
            setEndDate={setEndDateAndMaybeStartDate}
            endOffset={endOffset}
            setEndOffset={setEndOffset}
            budget={budget}
            setBudget={setBudget}
            eventTypeName={eventTypeName}
            setEventTypeName={setEventTypeName}
            forceValidate={forceValidate}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <NewContactArea
            title={
              contactView === 'edit' ? 'Create New Contact' : 'Add Contact'
            }
            contactView={contactView}
            onClickCreateNewPlanner={onClickCreateNewPlanner}
            onClickSearchResult={onClickSearchResult}
            planner={planner}
            setPlanner={setPlanner}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            accountName={accountName}
            setAccountName={setAccountName}
            forceValidate={forceValidate}
          />
        </Grid>
      </Grid>
    </PvDialog>
  );
};
